@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .custom-bullet-list li::marker{
    color: black; 
    font-weight: bold;
  }
}
